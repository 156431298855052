import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import QRCode from 'qrcode';
import { environment } from "@prcins/environments";

@Component({
  selector: 'prcins-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.css']
})
export class MobileAppComponent implements OnInit, AfterViewInit {

  qrData: any = null;
  @Input() allowWebContinue: boolean = false;
  @Input() allowAutoRedirect: boolean = false;
  constructor() { }

  ngOnInit() {
    if (this.allowAutoRedirect) {
      this.openStore()
    }
  }

  ngAfterViewInit() {
    const qrOpts = {
      errorCorrectionLevel: 'L',
      scale: 1,
      width: 100,
      margin: 0
    }

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('utm_source', 'qr');
    this.qrData = currentUrl.href.replace(currentUrl.origin, "https://mobileqa.plymouthrock.com");
    if (environment.production) {
      this.qrData = currentUrl.href.replace(currentUrl.origin, "https://mobile.plymouthrock.com");
    }

    QRCode.toCanvas(this.qrData, qrOpts, function (err, canvas) {
      if (err) throw err

      let container = document.getElementById('qrCodeContainer')
      if (container) {
        container.appendChild(canvas);
      }
    });
  }

  isAndroid() {
    return navigator.userAgent.match('Android');
  }

  isIOS() {
    return navigator.userAgent.match('iPad') ||
      navigator.userAgent.match('iPhone') ||
      navigator.userAgent.match('iPod');
  }

  isMobile() {
    return this.isAndroid() || this.isIOS();
  }

  openStore() {
    navigator.clipboard.writeText(this.qrData);

    if (this.isAndroid()) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.prac.eservice";
    } else if(this.isIOS()) {
      window.location.href = "https://apps.apple.com/app/apple-store/id632820710";
    }
  }

  continueOnWeb() {
    window.location.href =  window.location.href.replace("app", "login");
  }
}
